import { getPsid, getPlatform, isAndroid } from './utils';
import { MESSAGE_TYPES, LANGUAGES } from './constants';
import * as defaultMessages from './defaultMessages';
import * as chatbotText from './chatbotText'

const version = 1.1;
const brandName = 'vodafone';
const url = "https://vodafone-dev.oriserve.in";

export const network_check_url = "https://google.com/";

export const getSocketUrl = () => {
  const socket_url = `${url}/liveConversations?psid=${getPsid()}&ver=${version}&role=user&brandName=${brandName}&botName=${brandName}&channelName=${getPlatform()}`;
  return socket_url;
};

export const chatbot_client_info = {
  sentry_dsn: "https://fa80a3e669cc4ee78bcb94c405adecba@sentry.io/1512125",
  sender_id: "dish_chat_client",
  trigger: {
    visibility: false, //to enable custom trigger
    icon_height: 70, //in number only
    mobile_icon_width: 70, //in number only
    animation_duration: 2000, // in milliseceond only
    lottie_visibility: false, // to enable lottie icon
    lottie_icon_height: 70,
    lottie_icon_width: 70,
    show_close_icon: true,
  }
};

export const chatbot_setting = {
  automate_connection_time: 3600, //in seceond only
  automate_reset_chat_time: 3600 * 24 * 4, //in second only
  security: {
    enable: false,
    code: "123456",
  },
  gradient: {
    sender_bubble: false,
    trigger: false,
  },
  powered_by: {
    visibility: false, // to enable powered by tag
    target_url: "http://oriserve.com/"
  },
  notification_bot: {
    visibility: false, // to enable notification popup (set value false in android and ios )
    stack_view: false, // to show to stack view of notification bot
  },
  chat_interface: {
    show_bg_image: true, // to enable chatinterface background
    show_avatar: false,
    quick_reply_bg_transparent: true,
    scroll_upto_first_response_only: true,
    carousel_msg_display_type: 'default', // default, fixed, actual
    text_with_media_img_popup_disable: false,
    carousel_img_popup_disable: false,
    header_tag: true, //to hide and show tag type header for small screen
    query_params: {
      enable: true,
      query_param_key: 'chatbotopen',
    },
    type_writer: {
      enable: true,
      deleting_speed: 30,
      typing_speed: 150
    },
    speech_recognition: {
      enable: true,
    }
  },
  adster_bot: {
    visibility: true, // to enable adster require changes
    banner: true, // to add banner image code
    banner_initial_transtion_delay: 5000, // in ms
    banner_query_params_key: "segment",
    banner_images: {
      "default": {
        default: "https://dummyimage.com/420x200/000/fff.png&text=default-default",
        ad1: "https://dummyimage.com/420x200/000/fff.png&text=ad1-default",
        ad2: "https://dummyimage.com/420x200/000/fff.png&text=ad2-default",
      },
      "0-0.25": {
        default: "https://dummyimage.com/150x600/000/fff.png&text=default-0-0.25",
        ad1: "https://dummyimage.com/150x600/000/fff.png&text=ad1-0-0.25",
        ad2: "https://dummyimage.com/150x625/000/fff.png&text=ad2-0-0.25",
      },
      "0.26-0.5": {
        default: "https://dummyimage.com/320x650/000/fff.png&text=default-0.26-0.5",
        ad1: "https://dummyimage.com/320x650/000/fff.png&text=ad1-0.26-0.5",
        ad2: "https://dummyimage.com/300x600/000/fff.png&text=ad2-0.26-0.5",
      },
      "0.51-0.75": {
        default: "https://dummyimage.com/320x480/000/fff.png&text=default-0.51-0.75",
        ad1: "https://dummyimage.com/320x568/000/fff.png&text=ad1-0.51-0.75",
        ad2: "https://dummyimage.com/320x568/000/fff.png&text=ad2-0.51-0.75",
      },
      "0.76-1": {
        default: "https://dummyimage.com/380x500/000/fff.png&text=default-0.76-1",
        ad1: "https://dummyimage.com/380x500/000/fff.png&text=ad1-0.76-1",
        ad2: "https://dummyimage.com/380x500/000/fff.png&text=ad2-0.76-1",
      },
      "1.1-1.25": {
        default: "https://dummyimage.com/336x280/383438/fff.png&text=default-1.1-1.25",
        ad1: "https://dummyimage.com/336x280/383438/fff.png&text=ad1-1.1-1.25",
        ad2: "https://dummyimage.com/336x280/383438/fff.png&text=ad2-1.1-1.25"
      },
      "1.26-1.5": {
        default: "https://dummyimage.com/300x250/383438/fff.png&text=default-1.26-1.5",
        ad1: "https://dummyimage.com/1024x768/383438/fff.png&text=ad1-1.26-1.5",
        ad2: "https://dummyimage.com/1024x768/383438/fff.png&text=ad2-1.26-1.5"
      },
      "1.51-1.75": {
        default: "https://dummyimage.com/480x300/383438/fff.png&text=default-1.51-1.75",
        ad1: "https://dummyimage.com/480x300/383438/fff.png&text=ad1-1.51-1.75",
        ad2: "https://dummyimage.com/480x300/383438/fff.png&text=ad2-1.51-1.75"
      },
      "1.76-2": {
        default: "https://dummyimage.com/568x320/000/fff.png&text=default-1.75-2",
        ad1: "https://dummyimage.com/568x320/000/fff.png&text=ad1-1.75-2",
        ad2: "https://dummyimage.com/568x320/000/fff.png&text=ad1-1.75-2"
      }
    },
    getBannerByRange(range, key) {
      if (this.banner_images[range][key])
        return this.banner_images[range][key]
      return this.banner_images[range].default
    },
    getBannerByAspectRatio(aspectRatio, key) {
      if (aspectRatio <= 0.25)
        return this.getBannerByRange("0-0.25", key)
      else if (aspectRatio <= 0.5)
        return this.getBannerByRange("0.26-0.5", key)
      else if (aspectRatio <= 0.75)
        return this.getBannerByRange("0.51-0.75", key)
      else if (aspectRatio <= 1)
        return this.getBannerByRange("0.76-1", key)
      else if (aspectRatio <= 1.25)
        return this.getBannerByRange("1.1-1.25", key)
      else if (aspectRatio <= 1.5)
        return this.getBannerByRange("1.26-1.5", key)
      else if (aspectRatio <= 1.75)
        return this.getBannerByRange("1.51-1.75", key)
      else if (aspectRatio <= 2)
        return this.getBannerByRange("1.76-2", key)
      else
        return this.getBannerByRange("default", key)
    }
  },
  auto_emit_response: {
    enable: false,
    query_param_key: 'oribotmessage',
    payload: {
      type: MESSAGE_TYPES.TEXT,
      text: "first emit message",
    }
  },
  menu: {
    visible: true,
    children: {
      privacy_policy: true,
      terms_and_conditions: true,
      feedback: true,
      clear_chat: true,
    }
  },
  add_file: {
    web_enable: true,
    android_enable: true,
    ios_enable: false,
    max_file_size_allowed: 500000,
  }
};

export const chatbot_default_messages = {
  enable: true,
  query_param_key: "msg",
  delay: 1000, // in ms
  messages: {
    android: defaultMessages.android,
    web: defaultMessages.web,
    hindi: defaultMessages.hindi // testing purpose only
  },
  getDefaultMessages() {
    let defaultMsgs = this.messages.web
    if (isAndroid())
      defaultMsgs = this.messages.android
    if (this.enable) {
      //=========== BRAND SPECIFIC LOGIC ==========
      const query_params = new URLSearchParams(window.location.search);
      if (query_params.has(this.query_param_key)) {
        const key = query_params.get(this.query_param_key)
        const msgs = this.messages[key]
        if (msgs)
          defaultMsgs = msgs
      }
      //=================== END ===================
    }
    return defaultMsgs
  }
}

export const translator = {
  enable: true,
  query_param_key: 'lang',
  text: {
    [LANGUAGES.ENGLISH]: chatbotText.english,
    [LANGUAGES.ARABIC]: chatbotText.arabic,
    [LANGUAGES.HINDI]: chatbotText.hindi
  },
  getLanguage() {
    let lang = LANGUAGES.ENGLISH
    if (this.enable) {
      //=========== BRAND SPECIFIC LOGIC ==========
      const query_params = new URLSearchParams(window.location.search);
      if (query_params.has(this.query_param_key)) {
        const key = query_params.get(this.query_param_key)
        if (this.text[key])
          lang = key
      }
      //=================== END ===================
    }
    return lang
  }
}
