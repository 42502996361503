import React, { Component, Suspense } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import Badge from 'antd/lib/badge';
import merge from 'lodash/merge';
import SendingIcon from 'react-icons/lib/md/rotate-right';

import './index.scss';

import { LangContext } from './context'

import * as chatActions from '../data/redux/chat_details/actions';
import * as pageActions from '../data/redux/page_details/actions';

import {
  LANGUAGES,
  MESSAGE_TYPES,
  BUTTON_TYPES,
  BUTTON_SUB_TYPES,
  MESSAGE_SENDER,
  MESSAGE_READ_STATUS,
  EVENTS
} from '../data/config/constants';
import {
  LOCAL_STORAGE,
  PLATFORM,
  checkDevice,
  isAndroid,
  isIOS,
  uniqueId,
  fetchWithTimeout,
  showMessage
} from '../data/config/utils';
import {
  chatbot_client_info,
  chatbot_setting,
  translator,
  network_check_url
} from '../data/config/urls';

// import TriggerChatBot from '../components/triggerchatbot';

const ChatBot = React.lazy(() => import('./chatbot'));
// const NotificationBot = React.lazy(() => import('./notificationbot'));

class AppContainer extends Component {
  constructor(props) {
    super(props);
    window.androidObj = function AndroidClass() { };
    this.timeout = false;
    this.chatbotRef = React.createRef();
    this.state = {
      lang: LANGUAGES.ENGLISH,
      // load_adster: false,
      selected_checkbox_values: [],
      selected_offer: {
        offer_id: null,
        offer_name: null
      }
    }
  }

  componentDidMount() {
    const { chat_details, actions } = this.props;
    const android = isAndroid();
    const ios = isIOS();
    let self = this;
    const lang = translator.getLanguage()
    this.setState({ lang })
    //------------------------------------------------
    if (chatbot_setting.security.enable && !chat_details.secure) {
      const security_code = window.prompt(translator.text[lang].security_prompt)
      if (security_code && security_code === chatbot_setting.security.code)
        actions.updateChatsState({ secure: true })
    }
    if (chatbot_setting.auto_emit_response.enable  && chatbot_setting.adster_bot.visibility && !chat_details.is_socket_connected)
      this.handleSocketConnection(true)

    window.bot_popup = this.botPopup;
    actions.updatePageState({ device_data: checkDevice.deviceStatus() });
    window.addEventListener("resize", () => {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        self.props.actions.updatePageState({ device_data: checkDevice.deviceStatus() });
      }, 300);
    });
    this.checkInternetConnection();
    window.addEventListener('online', this.checkInternetConnection);
    window.addEventListener('offline', this.checkInternetConnection);
    document.addEventListener("visibilitychange", this.onScreenVisibilityChange);
    document.addEventListener("focusin", this.onScreenVisibilityChange);
    if (android || ios) {
      window.androidObj.updateFromAndroid = (type, data) => {
        const update_type = type.toLowerCase();
        if (update_type === PLATFORM.ANDROID) {
          localStorage.removeItem(PLATFORM.IOS);
        } else if (update_type === PLATFORM.IOS) {
          localStorage.removeItem(PLATFORM.ANDROID);
        }
        if (update_type === PLATFORM.ANDROID || update_type === PLATFORM.IOS) {
          localStorage.setItem(update_type, JSON.stringify(true));
        } else if (update_type === 'psid') {
          data = JSON.parse(data);
          if (data.psid) {
            localStorage.setItem(LOCAL_STORAGE.PSID, data.psid);
            actions.updateChatsState({ psid: data.psid });
          }
          if (data.params) {
            localStorage.setItem(LOCAL_STORAGE.APP_PARAMS, JSON.stringify(data.params));
          }
          if (!chat_details.is_socket_connected) {
            actions.updateChatsState({ messages: [] })
            actions.makeSocketConnection();
          }
        } else if (update_type === 'endchat' && this.chatbotRef && this.chatbotRef.current && this.chatbotRef.current.onClickCloseIcon) {
          this.chatbotRef.current.onClickCloseIcon();
        }
      }
      actions.handleChatbotInterface(true);
    }
    // else if (!chat_details.is_socket_connected) {
    //   if (chatbot_setting.adster_bot.visibility)
    //     this.handleSocketConnection(true);
    //   else {
    //     let last_emit = localStorage.getItem(LOCAL_STORAGE.LAST_EMIT) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE.LAST_EMIT)) : null;
    //     const query_params = new URLSearchParams(window.location.search);
    //     if (chatbot_setting.chat_interface.query_params.enable && query_params.has(chatbot_setting.chat_interface.query_params.query_param_key)) {
    //       if (query_params.get(chatbot_setting.chat_interface.query_params.query_param_key)) {
    //         this.handleSocketConnection(true);
    //       }
    //     } else if (last_emit) {
    //       let current_time = new Date().getTime();
    //       let time_gap = (current_time - last_emit) / 1000;
    //       if (time_gap < chatbot_setting.automate_connection_time) {
    //         actions.makeSocketConnection();
    //       } else {
    //         actions.handleChatbotInterface(false);
    //       }
    //       if (time_gap > chatbot_setting.automate_reset_chat_time) {
    //         actions.updateChatsState({ messages: [] })
    //         localStorage.setItem(LOCAL_STORAGE.MESSAGES, JSON.stringify([]));
    //       }
    //     } else {
    //       actions.handleChatbotInterface(false);
    //     }
    //   }
    // }
  }

  componentDidUpdate(prevProps) {
    const { actions } = this.props;
    const { is_socket_connected, is_internet_connected } = this.props.chat_details;
    if (!prevProps.chat_details.is_internet_connected && is_internet_connected && !is_socket_connected)
      actions.callSocketMethod('open')
    else if (prevProps.chat_details.is_internet_connected && !is_internet_connected && is_socket_connected)
      actions.callSocketMethod('close')
    if (prevProps.chat_details.is_socket_connected !== is_socket_connected)
      this.checkInternetConnection()
  }

  componentWillUnmount() {
    const { actions } = this.props;
    window.removeEventListener('online', this.checkInternetConnection);
    window.removeEventListener('offline', this.checkInternetConnection);
    document.removeEventListener("visibilitychange", this.onScreenVisibilityChange);
    document.removeEventListener("focusin", this.onScreenVisibilityChange);
    actions.socketDisconnect();
  }

  intractedWithChatbot = () => {
    const { chat_details } = this.props;
    if (!chat_details.is_socket_connected && chatbot_setting.adster_bot.visibility)
      this.handleSocketConnection(true);
  }

  onScreenVisibilityChange = () => {
    const { chat_details, actions } = this.props;
    if (document.visibilityState === 'visible' && chat_details.is_chat_open) {
      const payload = {
        clientPsid: chat_details.psid,
        senderPsid: chat_details.psid,
      };
      actions.emitCustomEvent(EVENTS.MESSAGE_SEEN, payload);
    }
  }

  checkInternetConnection = () => {
    const { actions, chat_details } = this.props;
    if (navigator.onLine) {
      if (!chat_details.internet_connection_checking) {
        actions.updateChatsState({ internet_connection_checking: true })
        fetchWithTimeout(network_check_url, {
          mode: 'no-cors',
        }).then(() => {
          actions.updateChatsState({
            internet_connection_checking: false,
            is_internet_connected: true
          })
        }).catch(error => {
          actions.updateChatsState({ internet_connection_checking: false })
          if (error && error.message === "timeout") {
            actions.updateChatsState({ is_internet_connected: false })
            showMessage('error', 'You are currently offline')
          }
        })
      }
    } else {
      actions.updateChatsState({ is_internet_connected: false })
      showMessage('error', 'You are currently offline')
    }
  }

  botPopup = (case_data, params) => {
    const { chat_details, actions } = this.props;
    let payload = {
      case: case_data,
      params: params,
      psid: chat_details.psid
    };
    actions.handleBotPopupRequest(payload);
  };

  handleSocketConnection = bool => {
    const { chat_details, actions } = this.props;
    const android = isAndroid();
    const ios = isIOS();
    actions.handleChatbotInterface(bool);
    if (bool && chat_details.is_socket_connected) {
      const payload = {
        clientPsid: chat_details.psid,
        senderPsid: chat_details.psid,
      };
      actions.emitCustomEvent(EVENTS.MESSAGE_SEEN, payload);
    }
    if (bool && !chat_details.is_socket_connected && !android && !ios) {
      actions.makeSocketConnection();
    }
  };

  handleOfferSelection = (offer_id, offer_name) => {
    this.setState({
      selected_offer: {
        ...this.state.selected_offer,
        offer_id,
        offer_name
      }
    });
  };

  setDefaultOfferState = () => {
    this.setState({
      selected_offer: {
        ...this.state.selected_offer,
        offer_id: null,
        offer_name: null,
      }
    });
  };

  onChangeCheckbox = (selected_checkbox_values) => {
    this.setState({ selected_checkbox_values });
  };

  emitResponseToServer = response => {
    const { chat_details, page_details, actions } = this.props;
    const android = isAndroid();
    const ios = isIOS();
    const data = {
      ...response,
      sender_id: chatbot_client_info.sender_id,
      navigator_userAgent: navigator.userAgent,
      navigator_platform: navigator.platform,
      variable_name: chat_details.variable_name,
      send_variable_to_apiai: chat_details.send_variable_to_apiai,
      sendVariableToLS: chat_details.sendVariableToLS,
      skipLS: chat_details.skipLS,
      adSegment: page_details.banner_key,
      bannerWidth: page_details.banner_width,
      bannerHeight: page_details.banner_height
    };
    if ((android || ios) && localStorage.getItem(LOCAL_STORAGE.APP_PARAMS)) {
      data.lockedParams = JSON.parse(localStorage.getItem(LOCAL_STORAGE.APP_PARAMS));
      localStorage.removeItem(LOCAL_STORAGE.APP_PARAMS);
    }
    if (!chat_details.is_socket_connected)
      actions.makeSocketConnection(() => actions.emitNewMessageToServer(data))
    else
      actions.emitNewMessageToServer(data);
    let emit_time = new Date().getTime();
    localStorage.setItem(LOCAL_STORAGE.LAST_EMIT, JSON.stringify(emit_time));
  };

  pushSenderNewMsgToChatbot = (type, data) => {
    const { actions } = this.props;
    const user_message = {
      type,
      sender_id: chatbot_client_info.sender_id,
      timestamp: new Date(),
      sender: MESSAGE_SENDER.CUSTOMER,
      readStatus: MESSAGE_READ_STATUS.SENDING,
      ...data,
    };
    actions.pushSenderMessage(user_message);
  };

  sendTextToServer = text => {
    const cmid = uniqueId();
    const response = {
      type: MESSAGE_TYPES.TEXT,
      text,
      cmid
    };
    const data = {
      payload: { text },
      cmid
    };
    this.emitResponseToServer(response);
    this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, data);
  };

  handleButtonSubTypes = data => {
    switch (data.button.subtype) {
      case BUTTON_SUB_TYPES.DISH_OFFERS:
        const { selected_offer } = this.state;
        const cmid = uniqueId();
        const response = {
          type: MESSAGE_TYPES.TEXT,
          text: selected_offer.offer_id,
          cmid
        };
        const payload_data = {
          payload: { text: selected_offer.offer_name },
          cmid,
        };
        this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, payload_data);
        this.emitResponseToServer(response);
        if (selected_offer.offer_id) {
          this.setDefaultOfferState();
        }
        break;

      case BUTTON_SUB_TYPES.CHECKBOX_SUBMIT:
        if (data.message && data.message.payload && data.message.payload.options) {
          const { selected_checkbox_values } = this.state;
          const selected_checkbox_items = data.message.payload.options.filter((item) => {
            return selected_checkbox_values.findIndex(value => value === item.value) !== -1;
          });
          if (selected_checkbox_items.length > 0) {
            const cmid = uniqueId();
            const response = {
              type: MESSAGE_TYPES.LIST,
              list: selected_checkbox_items,
              relayData: data.button.relayData,
              cmid
            };
            const obj = {
              payload: { list: selected_checkbox_items },
              cmid,
            };
            this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.LIST, obj);
            this.emitResponseToServer(response);
          }
        }
        break;

      case BUTTON_SUB_TYPES.SHARE_LOCATION:
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            const lat = position.coords.latitude;
            const long = position.coords.longitude;
            const cmid = uniqueId();
            const response = {
              type: MESSAGE_TYPES.LOCATION,
              lat: lat.toString(),
              long: long.toString(),
              cmid
            };
            const payload_data = {
              payload: { text: "You shared your location." },
              cmid,
            };
            this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, payload_data);
            this.emitResponseToServer(response);
          });
        }
        break;

      default:
        return;
    }
  };

  emitBtnCustomEvent = (eventName, data) => {
    const { actions } = this.props
    actions.emitCustomEvent(eventName, data, (err, res) => {
      if (err) {
        const payload = {
          cmid: data.cmid,
          changedValue: { readStatus: MESSAGE_READ_STATUS.FAILED }
        };
        actions.updateMessage(payload, 'cmid');
      } else if (!err && res && res.data && res.data.cmid && res.data.changedValue) {
        actions.updateMessage(res.data, 'cmid');
      }
    });
  }

  handleMsgBtnClick = data => {
    if (data.button) {
      if (window.parent && data.button.type !== BUTTON_TYPES.LINK) {
        window.parent.postMessage({
          type: 'counter',
          func: data.button.adEvent ? data.button.adEvent : data.button.text,
          message: ""
        }, '*')
      }
      switch (data.button.type) {
        case BUTTON_TYPES.LINK:
          if (data.button.url) {
            if (isAndroid() && window.androidObj && window.androidObj.textToAndroid) {
              window.androidObj.textToAndroid(JSON.stringify(data));
            } else if (isIOS()) {
              if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.textToIosForWebkit)
                window.webkit.messageHandlers.textToIosForWebkit.postMessage({ data })
              else
                eval("if(textToIos) textToIos(data)");
            } else {
              window.open(data.button.url, '_blank');
            }
            if (window.parent)
              window.parent.postMessage({
                type: 'exit',
                func: data.button.adEvent ? data.button.adEvent : data.button.text,
                message: data.button.url
              }, '*')
          }
          break;

        case BUTTON_TYPES.CUSTOM:
          if (data.button.subtype) {
            this.handleButtonSubTypes(data);
          }
          break;

        case BUTTON_TYPES.CUSTOM_SOCKET_EVENT: {
          const { actions, chat_details } = this.props;
          const cmid = uniqueId();
          if (data.button.text) {
            const obj = {
              payload: { text: data.button.text },
              cmid
            };
            this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, obj);
          }

          if (data.button.eventName) {
            let payload = {
              relayData: merge({}, data.message.relayData, data.button.relayData),
              text: data.button.text,
              type: data.message.payload && data.message.payload.expectedClientResponseType ? data.message.payload.expectedClientResponseType : MESSAGE_TYPES.TEXT,
              cmid
            };
            if (chat_details.is_socket_connected)
              this.emitBtnCustomEvent(data.button.eventName, payload)
            else
              actions.makeSocketConnection(() => this.emitBtnCustomEvent(data.button.eventName, payload))
          }
        }
          break;

        case BUTTON_TYPES.POST_BACK_RESPONSE: {
          const cmid = uniqueId();
          if (data.button.text) {
            const obj = {
              payload: { text: data.button.text },
              cmid
            };
            this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.TEXT, obj);
          }
          if (data.button.postbackRes) {
            const response = {
              type: MESSAGE_TYPES.TEXT,
              text: data.button.postbackRes,
              cmid
            };
            this.emitResponseToServer(response);
          }
        }
          break;

        default:
          if (data.button.text) {
            this.sendTextToServer(data.button.text);
          }
      }
    }
  };

  handleFileUpload = (data, message) => {
    if (data && data.fileUrl && data.file) {
      console.log('upload data', data);
      if (message) {
        const payload = {
          data,
          message,
        };
        this.props.actions.updateFileUploadMessage(payload);
      }
      const cmid = uniqueId();
      const response = {
        type: MESSAGE_TYPES.FILE,
        relayData: message && message.payload ? message.payload.relayData : null,
        fileBase64: data.fileUrl,
        cmid
      };
      this.emitResponseToServer(response);
      const obj = {
        payload: { title: data.file.name, imageUrl: data.fileUrl },
        cmid
      };
      this.pushSenderNewMsgToChatbot(MESSAGE_TYPES.IMAGE_WITH_BUTTONS, obj);
    }
  };

  render() {
    const { page_details, chat_details, actions } = this.props;
    if (chatbot_setting.security.enable && !chat_details.secure)
      return null
    return (
      <LangContext.Provider value={this.state.lang}>
        <div className="ori-app-container ori-ant-design-container oriAppContainer" onClick={this.intractedWithChatbot}>
          {/* <Badge count={chat_details.notification_count} overflowCount={9} className="ori-animated ori-fade-in notificationBadge">
          <TriggerChatBot is_chat_open={chat_details.is_chat_open} handleSocketConnection={this.handleSocketConnection} />
        </Badge> */}
          <Suspense fallback={<SendingIcon className="ori-l-mrgn-5 ori-animated ori-rotate ori-infinite" />}>
            {
              (chat_details.is_chat_open || chatbot_setting.adster_bot.visibility) &&
              <ChatBot
                ref={this.chatbotRef}
                screen_height={page_details.device_data.screen_height}
                banner_url={page_details.banner_url}
                chat_details={chat_details}
                actions={actions}
                sendTextToServer={this.sendTextToServer}
                handleMsgBtnClick={this.handleMsgBtnClick}
                handleFileUpload={this.handleFileUpload}
                handleOfferSelection={this.handleOfferSelection}
                onChangeCheckbox={this.onChangeCheckbox}
              />
            }
            {/* {
            chatbot_setting.notification_bot.visibility && !chat_details.is_chat_open && chat_details.unseen_messages.length > 0 &&
            <NotificationBot page_details={page_details} chat_details={chat_details} actions={actions} sendTextToServer={this.sendTextToServer} handleMsgBtnClick={this.handleMsgBtnClick} handleFileUpload={this.handleFileUpload} handleOfferSelection={this.handleOfferSelection} stack_view={chatbot_setting.notification_bot.stack_view} onChangeCheckbox={this.onChangeCheckbox} />
          } */}
          </Suspense>
        </div>
      </LangContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    chat_details: state.chat_details,
    page_details: state.page_details
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Object.assign({}, pageActions, chatActions), dispatch)
  };
};

AppContainer.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
