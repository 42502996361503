const states = {
  page_details: {
    device_data: {},
    banner_url: "",
    banner_key: "",
    banner_width: null,
    banner_height: null,
  }
}

export default states
